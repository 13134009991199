import { render, staticRenderFns } from "./airspace-changes-21-may-2020.md?vue&type=template&id=67dc7f20&scoped=true&"
var script = {}
import style0 from "./airspace-changes-21-may-2020.md?vue&type=style&index=0&id=67dc7f20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67dc7f20",
  null
  
)

export default component.exports